// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/user_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.capacity-page__modal .confirmation__btn {\n  min-width: 70px;\n}\n.capacity-page__cell-light-text {\n  color: #a7abbd;\n  font-size: 10px;\n}\n.capacity-page__cell-wrapper {\n  display: -webkit-box !important;\n  display: -ms-flexbox !important;\n  display: flex !important;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.capacity-page__cell-avatar {\n  position: relative;\n  display: inline-block;\n  width: 30px;\n  min-width: 30px;\n  height: 30px;\n  margin-right: 10px;\n  border-radius: 50%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-clip: content-box;\n  background-size: cover;\n  background-repeat: no-repeat;\n  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);\n          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);\n}\n.capacity-page__cell-fav::before {\n  width: 15px;\n  height: 15px;\n}\n.capacity-page__cell-btn {\n  width: auto;\n  min-width: 40px;\n  margin-left: auto;\n  padding: 0 5px;\n}\n", ""]);
// Exports
module.exports = exports;
